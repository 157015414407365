import React from "react"
import { graphql, Link } from "gatsby"
import { SectionTitle } from "../components/styled"
import Contact from "../components/Contact"
import WrapperRight from "../components/WrapperRight"
import { FaAngleRight } from "react-icons/fa"
import { Seo } from "../components/seo"
import { format } from "date-fns"
const JobDetails = ({ data }) => {
  const dataJobs = data.allStrapiJobs.nodes
  const actualDate = format(new Date(), "yyyy-MM-dd")
  const jobDate = dataJobs[0].DatePublication
  const actualDate2 = actualDate.split("-")
  const jobDate2 = jobDate.split("-")
  const sub1 = actualDate2[0] * 365 + actualDate2[1] * 30 + actualDate2[2]
  const sub2 = jobDate2[0] * 365 + jobDate2[1] * 30 + jobDate2[2]
  const rest = sub1 - sub2
  const { Imposible, Negotiable, Posible } = dataJobs[0].NewGraduates
    ? dataJobs[0].NewGraduates
    : ""
  const { None, ThereRegulation, Yes } = dataJobs[0].VisaSupport
    ? dataJobs[0].VisaSupport
    : ""
  const noImage = require("../images/icons/no-imagen.svg")
  const handleAddBrTag = string => {
    if (string === null) {
      return ""
    }
    return string.replace(/(\r\n|\n)/gm, "<br />")
  }
  return (
    <>
      <Seo
        title={`${data.allStrapiJobs.nodes[0].Title} | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
        image={
          data.allStrapiJobs.nodes[0].types[0]
            ? data.allStrapiJobs.nodes[0].types[0].image.publicURL
            : ""
        }
      />
      {/* jobPostTItle={data.allStrapiJobs.nodes[0].Title}
        jobPostDescription={data.allStrapiJobs.nodes[0].JobDescription}
        jobPostDate={data.allStrapiJobs.nodes[0].DatePublication} */}

      <section className="container jobs-listing">
        <div className="row">
          <div className="col-lg-9">
            <div className="jobs-listing__job-info jobs-listing__job-details">
              {dataJobs[0].Archived === true ? (
                <>
                  <div style={{ marginTop: "1em" }}>
                    <SectionTitle>
                      この求人の応募は終了いたしました。
                    </SectionTitle>
                    <hr className="u-line-bottom" />
                    <p className="u-margin-bottom-1 u-margin-top-1">
                      最新の求人情報は
                      <Link to="/jobs-listing" className="u-color-danger">
                        求人情報 一覧
                      </Link>
                      からご覧ください。
                    </p>
                  </div>
                  <hr className="u-line-bottom" />
                  <div className="job-close-text">
                    <p>
                      <b>
                        ご希望条件でご相談されたい方は、
                        <br />
                        下記からご登録をいただきますと担当者が求人のご紹介をさせていただきます。
                        <br />
                      </b>
                      ※既に面談済みの方は面談担当者にお問合わせください。
                    </p>
                    <div className="btns-job-close">
                      <Link
                        to="/job-form"
                        state={{ workid: dataJobs[0].Manualid }}
                      >
                        無料相談フォーム
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <hr className="u-line-bottom" />
              <>
                {dataJobs.map(jobs => (
                  <>
                    <div className="container-full jobs-listing__job-info--box-job">
                      <Link
                        className="u-display-text-head"
                        to={`/jobs-listing/${jobs.Manualid}`}
                      >
                        {jobs.Title}
                      </Link>
                      <div className="row">
                        <div className="jobs-listing__job-info--image  col-4 col-sm-3">
                          <div className="box-nro-item">
                            {jobs.types[0] ? (
                              <img src={jobs.types[0].image.publicURL} alt="" />
                            ) : (
                              <img className="no-imagen" src={noImage} alt="" />
                            )}
                            <span>No. {jobs.Manualid}</span>
                          </div>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--tags">
                          <h1 className="u-display-text">
                            <Link to={`/jobs-listing/${jobs.Manualid}`}>
                              {jobs.Title}
                            </Link>
                          </h1>
                          <ul>
                            {rest <= 7 && <li className="new-tag">NEW</li>}
                            {jobs.marks ? (
                              <>
                                {jobs.marks.map((marker, i) => (
                                  <li key={i}>
                                    {/* className="active-tag" */}
                                    <Link to={`/job-search/${marker.name}`}>
                                      {marker.name}
                                    </Link>
                                  </li>
                                ))}
                              </>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>職種分類</span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                          {jobs.types ? (
                            <>
                              {jobs.types.map((type, i) => (
                                <span key={i}>
                                  <Link to={`/jobs-types/${type.name}`}>
                                    {type.name}
                                  </Link>
                                </span>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>職種</span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                          <span>
                            <Link to={`/jobs-listing/${jobs.JobCategorie}`}>
                              {jobs.JobCategorie}
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>勤務地</span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description jobs-listing__job-info--description--display">
                          {jobs.locations ? (
                            <>
                              {jobs.locations.map((location, i) => (
                                // <span key={i}> {location.name}</span>
                                <>
                                  {location.name !== "その他" &&
                                  location.name !== "日本" ? (
                                    <>
                                      {i === 0 ? (
                                        <span style={{ marginRight: "5px" }}>
                                          メキシコ /
                                        </span>
                                      ) : (
                                        " "
                                      )}
                                      <span
                                        key={i}
                                        style={{ marginRight: "5px" }}
                                      >
                                        <Link
                                          to={`/jobs-location/${location.name}`}
                                        >
                                          {location.name}
                                        </Link>
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span
                                        key={i}
                                        style={{ marginRight: "5px" }}
                                      >
                                        <Link
                                          to={`/jobs-location/${location.name}`}
                                        >
                                          {location.name}
                                        </Link>
                                      </span>
                                    </>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                          <span>{jobs.LocationDescription}</span>
                        </div>
                      </div>
                      {jobs.JobDescription ? (
                        <div className="row">
                          <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                            <span>職務内容</span>
                          </div>
                          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: handleAddBrTag(jobs.JobDescription),
                              }}
                            ></span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>給与(額面)</span>
                        </div>
                        <div className="col-md-3 col-sm-3 col-8 jobs-listing__job-info--description">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: jobs.Salary,
                            }}
                          ></span>
                        </div>
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title jobs-listing__job-info--title--width">
                          <span>昇給/賞与</span>
                        </div>
                        <div className="col-4 col-sm-3 col-8 jobs-listing__job-info--description jobs-listing__job-info--description--width">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: handleAddBrTag(jobs.SalaryIncrease),
                            }}
                          ></span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>勤務時間</span>
                        </div>
                        <div className="col-md-3 col-sm-3 col-8 jobs-listing__job-info--description">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: handleAddBrTag(jobs.WorkingTime),
                            }}
                          ></span>
                        </div>
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title jobs-listing__job-info--title--width">
                          <span>休日/休暇</span>
                        </div>
                        <div className="col-4 col-sm-3 col-8 jobs-listing__job-info--description jobs-listing__job-info--description--width">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: jobs.HolidayVacation,
                            }}
                          ></span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>社会保険/任意保険</span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: handleAddBrTag(jobs.Insurance),
                            }}
                          ></span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>諸手当</span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: handleAddBrTag(jobs.Allowances),
                            }}
                          ></span>
                        </div>
                      </div>
                      {jobs.MandatoryRequirement ? (
                        <div className="row">
                          <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                            <span>必須要件</span>
                          </div>
                          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: handleAddBrTag(
                                  jobs.MandatoryRequirement
                                ),
                              }}
                            ></span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {jobs.WelcomeRequirement ? (
                        <div className="row">
                          <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                            <span>歓迎要件</span>
                          </div>
                          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: handleAddBrTag(jobs.WelcomeRequirement),
                              }}
                            ></span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row">
                        <div className=" col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>会社概要</span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: jobs.CompanyProfile,
                            }}
                          ></span>
                        </div>
                      </div>
                      {jobs.NewGraduates && jobs.VisaSupport ? (
                        <div className="row jobs-listing__buttons-description">
                          <p
                            className={`jobs-listing__buttons-description--${
                              (Posible && !Imposible && !Negotiable) ||
                              (!Posible && !Imposible && Negotiable)
                                ? "positive"
                                : "negative" ||
                                  (!Posible && Imposible && !Negotiable)
                                ? "negative"
                                : ""
                            }`}
                          >
                            新卒学生応募
                            <span>
                              {Posible && !Imposible && !Negotiable
                                ? "可能"
                                : "" || (!Posible && !Imposible && Negotiable)
                                ? "応相談"
                                : "" || (!Posible && Imposible && !Negotiable)
                                ? "不可"
                                : ""}
                            </span>
                            {/* 新卒学生応募 不可 disabled */}
                          </p>
                          <p
                            className={`jobs-listing__buttons-description--${
                              (Yes && !ThereRegulation && !None) ||
                              (!Yes && ThereRegulation && !None)
                                ? "positive"
                                : "negative" ||
                                  (!Yes && !ThereRegulation && None)
                                ? "negative"
                                : ""
                            }`}
                          >
                            ビザ取得サポート
                            <span>
                              {Yes && !ThereRegulation && !None
                                ? "あり"
                                : "" || (!Yes && ThereRegulation && !None)
                                ? "規定有"
                                : "" || (!Yes && !ThereRegulation && None)
                                ? "なし"
                                : ""}
                            </span>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="row jobs-listing__text-description">
                        <b>
                          詳細は登録面談にてお伝えします。ご登録をご希望の方はこちら{" "}
                        </b>
                        <br></br>
                        <span className="jobs-listing__text-description--font">
                          ※既に面談済みの方は面談担当者にお問合わせください。
                        </span>
                      </div>
                      <div className="row jobs-listing__links">
                        <Link
                          to="/job-form"
                          state={{ workid: dataJobs[0].Manualid }}
                        >
                          <FaAngleRight />
                          今すぐ無料相談
                        </Link>
                      </div>
                    </div>
                  </>
                ))}
              </>
            </div>

            <Contact />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default JobDetails

export const query = graphql`
  query JobDetailsQuery($slug: String!) {
    allStrapiJobs(
      filter: { Manualid: { eq: $slug } }
    ) { 
      nodes {
        Allowances
        Archived
        CompanyProfile
        DatePublication
        HolidayVacation
        Insurance
        JobDescription
        Manualid
        Salary
        SalaryIncrease
        MandatoryRequirement
        WelcomeRequirement
        Title
        WorkingTime
        JobCategorie
        LocationDescription
        NewGraduates {
          Imposible
          Negotiable
          Posible
        }
        locations {
          name
        }
        marks {
          name
        }
        types {
          name
          image {
            publicURL
          }
        }
        VisaSupport {
          None
          ThereRegulation
          Yes
        }
        Archived
        DatePublication
      }
    }
  }
`
